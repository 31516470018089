<template>
  <section>
    <b-overlay
      :show='loading'
      opacity='0.7'
      spinner-small
      spinner-variant='primary'
      style='min-height: 200px'
      class='d-inline-block w-100'
    >
      <section class='w-100 text-center mt-3' v-if='user && user.id'>
        <h6>Usuário: <strong>{{ user.username }}</strong></h6>
        <h6>Data: <strong>{{ formatDate(user.currentDate) }}</strong></h6>
      </section>
      <section v-if='simplifiedHierarchy.length > 0 || completeHierarchy.length > 0' class='mb-3'>
        <section v-if='completeHierarchy.length > 0'>
          <section class='type-hierarchy'>
            <p><strong>Hierarquia Completa</strong></p>
          </section>
          <ul class='tree mt-5' v-for='(company, i) in completeHierarchy' :key='`${company._id}-${i}`'>
            <li>
              <section class='item'>
            <span>
              Empresa <br />
              Nome: {{ company.name }}
            </span>
              </section>
            </li>

            <ul v-for='(unit, y) in company.units' :key='`${unit._id}-${y}`'>
              <li>
                <section class='item'>
              <span>
                Unidade <br />
                Nome: {{ unit.name }}
              </span>
                </section>
              </li>

              <ul v-for='(sector, z) in unit.sectors' :key='`${sector._id}-${z}`'>
                <li>
                  <section class='item'>
                <span>
                  Setor <br />
                  Nome: {{ unit.name }}
                </span>
                    <span v-if='sector.body && sector.body.description'>
                  Descrição: {{ sector.body.description }}
                </span>
                  </section>
                </li>

                <ul v-for='(role, b) in sector.roles' :key='`${role._id}-${b}`'>
                  <li>
                    <section class='item'>
                  <span>
                    Cargo <br />
                    Nome: {{ role.name }}
                  </span>
                      <span v-if='role.body && sector.body.description'>
                    Descrição: {{ role.body.description }}
                  </span>
                    </section>
                  </li>

                  <ul v-for='(product, a) in role.products' :key='`${product._id}-${a}`'>
                    <li>
                      <section class='item'>
                    <span>
                      Produto <br />
                      Nome: {{ product.name }}
                    </span>
                        <span v-if='product.body && sector.body.description'>
                      Descrição: {{ product.body.description }}
                    </span>
                      </section>
                    </li>
                    <ul>
                      <li v-if='product.termsofuses'>
                        <section class='item'>
                      <span>
                        Condiçoes de uso <br />
                      </span>
                          <section v-if='product.termsofuses.body'>
                        <span v-if='product.termsofuses.body.temperature'>
                          Tempertura: {{ product.termsofuses.body.temperature }} C°
                        </span>

                            <span v-if='product.termsofuses.body.ventilationIdentified'>
                          Ventilação identificada no Local:
                          {{ optionsVentilationIdentified.find((x) => x.key == product.termsofuses.body.ventilationIdentified).value
                              }}
                        </span>

                            <span v-if='product.termsofuses.body.quantityUsed'>
                          Quantidade diária utilizada deste produto:
                          {{ optionsQuantityUsed.find((x) => x.key == product.termsofuses.body.quantityUsed).value }}
                        </span>

                            <span v-if='product.termsofuses.body.frequencyUse'>
                          Frequência diária de utilização deste produto:
                          {{ optionsFrequencyUse.find((x) => x.key == product.termsofuses.body.frequencyUse).value }}
                        </span>

                            <span v-if='product.termsofuses.body.exposureTime'>
                          Tempo de exposição (do trabalhador com o uso do produto):
                          {{ optionsExposureTime.find((x) => x.key == product.termsofuses.body.exposureTime).value }}
                        </span>

                            <span v-if='product.termsofuses.body.exposedWorkers'>
                          Quantidade de trabalhadores expostos:
                          {{ product.termsofuses.body.exposedWorkers }}
                        </span>

                            <span v-if='product.termsofuses.body.degreeOfDust'>
                          Grau de empoeiramento:
                          {{ optionsDegreeOfDust.find((x) => x.key == product.termsofuses.body.degreeOfDust).name }}
                        </span>

                            <span v-if='product.termsofuses.body.description'>
                          Descrição:
                          {{ product.termsofuses.body.description }}
                        </span>
                          </section>
                        </section>
                      </li>

                      <ul>
                        <li>
                          <section class='item'>
                            <p>
                              Agentes <br />
                            </p>
                          </section>
                        </li>

                        <ul v-for='(agent, a) in product.agents' :key='`${agent._id}-${a}`'>
                          <li>
                        <span>
                          CAS: Agente: {{ agent.name }}
                        </span>
                          </li>
                        </ul>
                      </ul>
                    </ul>
                  </ul>
                </ul>
              </ul>
            </ul>
          </ul>
        </section>

        <section v-if='simplifiedHierarchy.length'>
          <section class='type-hierarchy mt-5'>
            <p><strong>Hierarquia Simplificada</strong></p>
          </section>

          <ul class='tree mt-5' v-for='(sector, i) in simplifiedHierarchy' :key='`${sector._id}-${i}`'>
            <li>
              <section class='item'>
                <span>
                  Setor <br />
                  Nome: {{ sector.name }}
                </span>
                <span v-if='sector.body && sector.body.description'>
                  Descrição: {{ sector.body.description }}
                </span>
              </section>
            </li>

            <ul v-for='(role, b) in sector.roles' :key='`${role._id}-${b}`'>
              <li>
                <section class='item'>
                  <span>
                    Cargo <br />
                    Nome: {{ role.name }}
                  </span>
                  <span v-if='role.body && sector.body.description'>
                    Descrição: {{ role.body.description }}
                  </span>
                </section>
              </li>

              <ul v-for='(product, a) in role.products' :key='`${product._id}-${a}`'>
                <li>
                  <section class='item'>
                    <span>
                      Produto <br />
                      Nome: {{ product.name }}
                    </span>
                    <span v-if='product.body && sector.body.description'>
                      Descrição: {{ product.body.description }}
                    </span>
                  </section>
                </li>
                <ul>
                  <li v-if='product.termsofuses'>
                    <section class='item'>
                      <span>
                        Condiçoes de uso <br />
                      </span>
                      <section v-if='product.termsofuses.body'>
                        <span v-if='product.termsofuses.body.temperature'>
                          Tempertura: {{ product.termsofuses.body.temperature }} C°
                        </span>

                        <span v-if='product.termsofuses.body.ventilationIdentified'>
                          Ventilação identificada no Local:
                          {{ optionsVentilationIdentified.find((x) => x.key == product.termsofuses.body.ventilationIdentified).value
                          }}
                        </span>

                        <span v-if='product.termsofuses.body.quantityUsed'>
                          Quantidade diária utilizada deste produto:
                          {{ optionsQuantityUsed.find((x) => x.key == product.termsofuses.body.quantityUsed).value }}
                        </span>

                        <span v-if='product.termsofuses.body.frequencyUse'>
                          Frequência diária de utilização deste produto:
                          {{ optionsFrequencyUse.find((x) => x.key == product.termsofuses.body.frequencyUse).value }}
                        </span>

                        <span v-if='product.termsofuses.body.exposureTime'>
                          Tempo de exposição (do trabalhador com o uso do produto):
                          {{ optionsExposureTime.find((x) => x.key == product.termsofuses.body.exposureTime).value }}
                        </span>

                        <span v-if='product.termsofuses.body.exposedWorkers'>
                          Quantidade de trabalhadores expostos:
                          {{ product.termsofuses.body.exposedWorkers }}
                        </span>

                        <span v-if='product.termsofuses.body.degreeOfDust'>
                          Grau de empoeiramento:
                          {{ optionsDegreeOfDust.find((x) => x.key == product.termsofuses.body.degreeOfDust).name }}
                        </span>

                        <span v-if='product.termsofuses.body.description'>
                          Descrição:
                          {{ product.termsofuses.body.description }}
                        </span>
                      </section>
                    </section>
                  </li>

                  <ul>
                    <li>
                      <section class='item'>
                        <p>
                          Agentes <br />
                        </p>
                      </section>
                    </li>

                    <ul v-for='(agent, a) in product.agents' :key='`${agent._id}-${a}`'>
                      <li>
                        <span>
                          CAS: Agente: {{ agent.name }}
                        </span>
                      </li>
                    </ul>
                  </ul>
                </ul>
              </ul>
            </ul>
          </ul>
        </section>
      </section>
      <section v-else class='type-hierarchy'>
        <p><strong>O usuário não possui informações cadastradas no inventário químico</strong></p>
      </section>
    </b-overlay>
  </section>
</template>

<script>
import format from 'date-fns/esm/format/index'
import api from '../utils/api'
import { mapActions } from 'vuex'
import moment from 'moment/moment'

export default {
  name: 'Hierarchy',
  data() {
    return {
      completeHierarchy: [],
      simplifiedHierarchy: [],
      optionsVentilationIdentified: [
        {
          model: 'ventilationIdentified',
          img: 'ho-facil-blue.png',
          key: 1,
          value: 'Ambiente aberto com ventilação natural'
        },
        {
          model: 'ventilationIdentified',
          img: 'ho-facil-blue.png',
          key: 2,
          value: 'Ventilação geral com menos de 3 a 6 trocas de ar por hora'
        },
        {
          model: 'ventilationIdentified',
          img: 'ho-facil-blue.png',
          key: 3,
          value:
            'Boa ventilação geral com menos de 6 a 12 trocas de ar por hora'
        },
        {
          model: 'ventilationIdentified',
          img: 'ho-facil-blue.png',
          key: 4,
          value:
            'Boa ventilação geral com exaustão local nos pontos de emissão'
        },
        {
          model: 'ventilationIdentified',
          img: 'ho-facil-blue.png',
          key: 5,
          value: 'Captura no ponto de emissão com contenção'
        },
        {
          model: 'ventilationIdentified',
          img: 'ho-facil-blue.png',
          key: 6,
          value: 'Enclausuramento do agente (uma barreira de proteção)'
        },
        {
          model: 'ventilationIdentified',
          img: 'ho-facil-blue.png',
          key: 7,
          value: 'Enclausuramento primário e secundário (duas barreiras de proteção)'
        }
      ],
      optionsQuantityUsed: [
        {
          key: 1,
          value: 'Menos que 1 kg ou L'
        },
        {
          key: 2,
          value: 'Entre 1 a 10 kg ou L'
        },
        {
          key: 3,
          value: 'Entre 10 a 50kg ou L'
        },
        {
          key: 4,
          value: 'Entre 50 a 100 Kg ou L'
        },
        {
          key: 5,
          value: 'Entre 100 a 1000 kg ou L'
        },
        {
          key: 6,
          value: 'Mais que 1000 Kg ou L'
        }
      ],
      optionsFrequencyUse: [
        {
          key: 1,
          value: 'Mais que 2x por dia'
        },
        {
          key: 2,
          value: 'De 1 a 2x por dia'
        },
        {
          key: 3,
          value: 'Mais que 2x por semana'
        },
        {
          key: 4,
          value: 'De 1 a 2x por semana'
        },
        {
          key: 5,
          value: 'Mais que 2x por mês'
        },
        {
          key: 6,
          value: 'Menos que 1 a 2x por mês'
        }
      ],
      optionsExposureTime: [
        {
          key: 1,
          value: 'Maior que 4h por dia'
        },
        {
          key: 2,
          value: 'Entre 2 a 4h por dia'
        },
        {
          key: 3,
          value: 'Entre 1 a 2h por dia'
        },
        {
          key: 4,
          value: 'Entre 30 a 60 min por dia'
        },
        {
          key: 5,
          value: 'Entre 10 a 30 min por dia'
        },
        {
          key: 6,
          value: 'Menor que 10 min por dia'
        }
      ],
      physicalProductStates: [
        {
          key: '1',
          value: 'Sólido'
        },
        {
          key: '2',
          value: 'Líquido'
        }
      ],
      optionsDegreeOfDust: [
        {
          key: 1,
          name: 'Baixo',
          description: `Escamas grandes ou grânulos grossos - quando manipulados, produzem pouca poiera
                        (exemplo: grânulos de PVC, flocos de cera, pellets que não se quebram)`
        },
        {
          key: 2,
          name: 'Médio',
          description: `Escamas granulares e cristalinos - quando manipulados, vê-se a poeira que logo se
                        deposita (exemplo: sabão em pó, granulares ou cristalinos)`
        },
        {
          key: 3,
          name: 'Alto',
          description: `Poeiras finas e leves - quando manipulados, observa-se formção de nuvens de poiras
                        que ficam muitos minutos no ar (exemplo: cimento, pó de giz, carvão, sólidos finos
                        ou poeiras muito leves)`
        }
      ],
      user: {},
      loading: false
    }
  },
  computed: {},
  methods: {
    ...mapActions('hierarchy', {
      handleGetHierarchy: 'handleGetHierarchy'
    }),
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    }
  },
  async mounted() {
    try {
      this.loading = true
      const { userId } = this.$route.params
      const sessionId = localStorage.getItem('sessionId')
      const { data } = await this.$api.get(`users/${sessionId}`)
      this.user = data
      const { hierarchy } = await this.handleGetHierarchy(userId)
      const { complete, simplified } = hierarchy
      this.completeHierarchy = complete
      this.simplifiedHierarchy = simplified
      setTimeout(() => {
        window.print()
      }, 1000)
      this.loading = false
    } catch (e) {

    }

  }
}
</script>

<style lang='scss' scoped>
.tree,
.tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
  color: var(--navy-blue);
  position: relative;
}

.tree ul {
  margin-left: 2em
}

.tree:before,
.tree ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
  border-left: 1px solid;
}

ul.tree:before {
  border-left: none
}

.tree li {
  margin: 0;
  padding: 0 2em;
  line-height: 2em;
  font-weight: bold;
  position: relative;
}

.tree span,
.tree folder {
  display: block;
}

.tree li:before {
  content: "";
  display: block;
  width: 20px;
  height: 0;
  border-top: 1px solid;
  margin-top: 1px;
  position: absolute;
  top: 1em;
  left: 4px;
}

ul.tree > li:before {
  border-top: none;
}

.item {
  border-bottom: 1px solid var(--navy-blue);
  width: max-content;
  margin-left: 4px;
  padding-left: 3px;
  //line-height: 25px;
}

.type-hierarchy {
  margin: 0 35px -30px 35px;
  border-bottom: 1px solid var(--navy-blue);

  .text-type-hierarchy {

  }
}
</style>
